import { useEffect, useState } from 'react';
import hljs from 'highlight.js';
import javascript from 'highlight.js/lib/languages/javascript';

import Header from '../../components/Header';

import './highlight.css';
import './vs2015.css';

const JAVASCRIPT = 'javascript';

const TEXT_PROMPT = (
  `import { chat } from '@fasterhorses/sdk';\n\nconst apiKey = 'FH_TEST';\nconst prompt = 'Tell me all about Paris';\n\nconst reply = await chat({\n  apiKey,\n  prompt\n});\n\nconsole.log(reply);`
);

const IMAGE_PROMPT = (
  `import { txt2img } from '@fasterhorses/sdk';\n\nconst apiKey = 'FH_TEST';\nconst prompt = 'a cyberpunk kitty fighting ninjas';\n\nconst imageSrc = await txt2img({\n  apiKey,\n  prompt\n});\n\nconsole.log(imageSrc);`
);

const RAG_PROMPT = (
  `import { train, ask } from '@fasterhorses/sdk';\n\nconst apiKey = 'FH_TEST';\nconst text = 'The answer is 5.';\n\nawait train({\n  apiKey,\n  documents: [text]\n});\n\nconst prompt = 'What is the answer?';\n\nconst answer = await ask({\n  apiKey,\n  prompt\n});\n\nconsole.log(answer);`
);

const VIDEO_PROMPT = (
  `import { txt2video } from '@fasterhorses/sdk';\n\nconst apiKey = 'FH_TEST';\nconst prompt = 'a white limo by the ocean at sunset';\n\nconst videoSrc = await txt2video({\n  apiKey,\n  prompt\n});\n\nconsole.log(videoSrc);`
);

const Home = () => {
  const [chatCode, setChatCode] = useState();
  const [txt2ImgCode, setTxt2ImgCode] = useState();
  const [ragCode, setRagCode] = useState();
  const [txt2VideoCode, setTxt2VideoCode] = useState();

  useEffect(() => {
    hljs.registerLanguage(JAVASCRIPT, javascript);

    const { value: chat } = hljs.highlight(
      TEXT_PROMPT,
      { language: JAVASCRIPT }
    );

    if (chat) {
      setChatCode(chat);
    }

    const { value: txt2img } = hljs.highlight(
      IMAGE_PROMPT,
      { language: JAVASCRIPT }
    );

    if (txt2img) {
      setTxt2ImgCode(txt2img);
    }

    const { value: rag } = hljs.highlight(
      RAG_PROMPT,
      { language: JAVASCRIPT }
    );

    if (rag) {
      setRagCode(rag);
    }

    const { value: txt2video } = hljs.highlight(
      VIDEO_PROMPT,
      { language: JAVASCRIPT }
    );

    if (txt2video) {
      setTxt2VideoCode(txt2video);
    }
  }, []);

  return <>
    <Header />
    <main className="landing">
      <div className="page">
        <section>
          <h3>Imagination at scale.</h3>
          <h4>Supercharge your work with real intelligence.</h4>
          <img
            src="/img/product.png"
            alt="Faster Horses"
            width={1458}
            height={812}
            style={{
              width: '100%',
              height: '100%'
            }}
          />
          <div className="cta">
            <p>Free-to-use AI chat &amp; image generation software.</p>
            <a href="/login" className="cta-button">
              <span>Get Started</span>
            </a>
          </div>
          <h4 className="heading">Models</h4>
          <div className="info">
            <h2>Model A</h2>
            <p>Our flagship service, based on Mistral 7b with some prompt constraints and contextual enrichment to refine outputs. Model A is a top-tier chat model that feels the most like using a raw LLM, with just enough app-level functionality to reduce noise and improve productivity.</p>
            <div className="product">
              <div>
                <h3>Model Details</h3>
                <p>High-quality text-to-text LLM</p>
                <p>Based on Mistral 7b</p>
                <p>Highly extensible and flexible LLM with minimal prompt enrichment</p>
                <p>32K token context</p>
                <p>Infinite context-isolated sessions</p>
                <p>$0.01/1K words</p>
              </div>
              <div>
                <pre className="hljs">
                  <code dangerouslySetInnerHTML={{ __html: chatCode }} />
                </pre>
              </div>
            </div>
            <div className="cta">
              <a target="_blank" rel="noreferrer" href="https://chat.fasterhorses.xyz" className="cta-button">
                <span>Try Chat&nbsp;↗</span>
              </a>
            </div>
            <h2>Painter SD</h2>
            <p>Our first text-to-image model, based on Stable Diffusion with added app-level session history to enable iterative prompt extension. Now you can prompt between results to narrow down the exact artwork you envision.</p>
            <div className="product">
              <div>
                <pre className="hljs">
                  <code dangerouslySetInnerHTML={{ __html: txt2ImgCode }} />
                </pre>
              </div>
              <div>
                <h3>Model Details</h3>
                <p>High-quality text-to-image model</p>
                <p>Based on Stable Diffusion</p>
                <p>Iterative prompt extension capabilities for ongoing creation</p>
                <p>512x512 or 1024x1024</p>
                <p>Infinite context-isolated sessions</p>
                <p>$0.01/1M pixels</p>
              </div>
            </div>
          </div>
          <div className="cta">
            <a target="_blank" rel="noreferrer" href="https://txt2img.fasterhorses.xyz" className="cta-button">
              <span>Try txt2img&nbsp;↗</span>
            </a>
          </div>
          <h2>Spinach</h2>
          <p>Mistral 7b with added retrieval-augmented generation (RAG) capabilities to enable domain-specific responses with high accuracy. Spinach is an advanced RAG-based approach that pre-computes the LLM context from files (user uploaded or otherwise) resulting in precise, extensible knowledge.</p>
          <div className="product">
            <div>
              <h3>Model Details</h3>
              <p>Advanced text-to-text LLM with RAG</p>
              <p>Based on Mistral 7b</p>
              <p>Dynamically extend context with file uploads</p>
              <p>Upload up to 1GB of data</p>
              <p>Infinite context-isolated sessions</p>
              <p>$0.02/1K words (includes file uploads)</p>
            </div>
            <div>
              <pre className="hljs">
                <code dangerouslySetInnerHTML={{ __html: ragCode }} />
              </pre>
            </div>
          </div>
          <div className="cta">
            <a target="_blank" rel="noreferrer" href="https://rag.fasterhorses.xyz" className="cta-button">
              <span>Try RAG&nbsp;↗</span>
            </a>
          </div>
          <span style={{ display: 'none' }}>
            <h2>Mustang</h2>
            <p>Our first text-to-video model, based on Stable Video 4D (SV4D) with added app-level session history to enable iterative prompt extension. Prompt between results to narrow down the exact shot you envision.</p>
            <div className="product">
              <div>
                <pre className="hljs">
                  <code dangerouslySetInnerHTML={{ __html: txt2VideoCode }} />
                </pre>
              </div>
              <div>
                <h3>Model Details</h3>
                <p>High-quality text-to-video model</p>
                <p>Based on Stable Video 4D (SV4D)</p>
                <p>Iterative prompt extension capabilities for ongoing creation</p>
                <p>512x512</p>
                <p>Infinite context-isolated sessions</p>
                <p>$0.02/1 second</p>
              </div>
            </div>
            <div className="cta">
              <a target="_blank" rel="noreferrer" href="https://txt2video.fasterhorses.xyz" className="cta-button">
                <span>Try txt2video&nbsp;↗</span>
              </a>
            </div>
          </span>
          <aside id="footer">
            <div>&copy;&nbsp;{new Date().getFullYear()}</div>
            <a href="https://www.linkedin.com/company/fasterhorses-xyz/" target="_blank" rel="noreferrer">LinkedIn</a>
            <a href="https://www.npmjs.com/package/@fasterhorses/sdk" target="_blank" rel="noreferrer">npm</a>
          </aside>
        </section>
      </div>
    </main>
  </>;
};

export default Home;
