import useToken from '../../hooks/useToken';

import './styles.css';

const Header = () => {
  const { user } = useToken({
    redirect: false
  });

  const userId = user?.id;

  return <header className="header">
    <a href="/" className="branding">
      <img src="/img/fasterhorses-logotype.png" alt="Faster Horses" width={2991} height={377} />
    </a>
    <nav>
      <a target="_blank" rel="noreferrer" href="https://www.zdifferentiator.com/z/fasterhorses">News</a>
      <a href="/faq">FAQ</a>
    </nav>
    {!userId && <div className="login-cta-link">
      <a href="/login">Login</a>
    </div>}
    {user && <p className="user">Logged in as {userId}</p>}
  </header>;
};

export default Header;
