import Header from '../../components/Header';

const FAQ = () => <>
  <Header />
  <main className="landing">
    <div className="page">
      <section>
        <h3>FAQ</h3>
        <h4>Some answers to frequently asked questions.</h4>
        <div className="faq">
          <strong>Q: What image sizes are supported?</strong>
          <p>A: 512x512 and 1024x1024.</p>
        </div>
        <aside id="footer">
          <div>&copy;&nbsp;{new Date().getFullYear()}</div>
          <a href="https://www.linkedin.com/company/fasterhorses-xyz/" target="_blank" rel="noreferrer">LinkedIn</a>
          <a href="https://www.npmjs.com/package/@fasterhorses/sdk" target="_blank" rel="noreferrer">npm</a>
        </aside>
      </section>
    </div>
  </main>
</>;

export default FAQ;
