import { useEffect, useState } from 'react';

const useToken = ({
  redirect = true,
  uri = '/login'
} = {
  redirect: true,
  uri: '/login'
}) => {
  const { API_URL } = window;

  const [token, setToken] = useState();
  const [user, setUser] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const toRedirect = () => {
    setIsLoading(false);
    localStorage.clear();

    if (redirect) {
      window.location.href = uri;
    }
  };

  const fetchToken = async () => {
    setIsLoading(true);

    const sessionData = localStorage.getItem('FH_SESSION');

    if (sessionData) {
      const {
        user: authUser,
        token: accessToken
      } = JSON.parse(sessionData);

      if (!accessToken) {
        window.location.href = uri;
      }

      const response = await fetch(`${API_URL}/signin`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          id: authUser.id,
          token: accessToken
        })
      });

      setIsLoading(false);

      if (response?.ok) {
        const {
          success,
          token: refreshToken,
          user: refreshUser
        } = await response.json();

        if (success && refreshToken) {
          setToken(refreshToken);
          setUser(refreshUser);

          localStorage.setItem(
            'FH_SESSION',
            JSON.stringify({
              token: refreshToken,
              user: refreshUser
            })
          );
        } else {
          toRedirect();
        }
      } else {
        toRedirect();
      }
    } else {
      toRedirect();
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchToken();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    token,
    user,
    refetch: fetchToken,
    isLoading
  };
};

export default useToken;
