import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';

import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));

window.API_URL = 'https://www.exactchange.network/fasterhorses/v1';
// window.API_URL = 'http://localhost:1337/fasterhorses/v1';

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
