import { useEffect, useState } from 'react';

import Header from '../../components/Header';
import useToken from '../../hooks/useToken';

const Auth = () => {
  const { API_URL } = window;

  const [userId, setUserId] = useState('');
  const [userIdLogin, setUserIdLogin] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [passwordLogin, setPasswordLogin] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { token } = useToken({
    redirect: false
  });

  useEffect(() => {
    if (token) {
      window.location.href = '/';
    }
  }, [token]);

  const onClickSignIn = async () => {
    setIsLoading(true);

    const response = await fetch(`${API_URL}/signin`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        id: userIdLogin,
        password: passwordLogin
      })
    });

    if (response?.ok) {
      const {
        success,
        token,
        user
      } = await response.json();

      setIsLoading(false);

      if (success && token) {
        localStorage.setItem(
          'FH_SESSION',
          JSON.stringify({ token, user })
        );

        window.location.reload();
      }
    } else {
      setIsLoading(false);
    }
  };

  const onClickSignUp = async () => {
    setIsLoading(true);

    const response = await fetch(`${API_URL}/signup`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        id: userId,
        email,
        password
      })
    });

    if (response?.ok) {
      const {
        message,
        success
      } = await response.json();

      setIsLoading(false);

      if (message) {
        alert(message);
      }

      if (success) {
        window.location.reload();
      }
    } else {
      setIsLoading(false);
    }
  };

  const onChangeUserId = ({ target: { value }}) => setUserId(value);

  const onChangeUserIdLogin = ({ target: { value }}) => setUserIdLogin(value);

  const onChangeEmail = ({ target: { value }}) => setEmail(value);

  const onChangePassword = ({ target: { value }}) => setPassword(value);

  const onChangePassword2 = ({ target: { value }}) => setPassword2(value);

  const onChangePasswordLogin = ({ target: { value }}) => setPasswordLogin(value);

  return <div className="landing">
    <Header />
    <div className="form page">
      <section>
        <h2>Sign in</h2>
        <input
          type="email"
          placeholder="user id"
          onChange={onChangeUserIdLogin}
          value={userIdLogin}
        />
        <input
          type="password"
          placeholder="password"
          onChange={onChangePasswordLogin}
          value={passwordLogin}
        />
        <button onClick={onClickSignIn}>Sign In</button>
      </section>
      <section style={{ borderTop: '1px solid #1e293b' }}>
        <h2>Sign up</h2>
        <input
          placeholder="user id (display name)"
          onChange={onChangeUserId}
          value={userId}
        />
        <input
          type="email"
          placeholder="email address"
          onChange={onChangeEmail}
          value={email}
        />
        <input
          type="password"
          placeholder="password"
          onChange={onChangePassword}
          value={password}
        />
        <input
          type="password"
          placeholder="password (again)"
          onChange={onChangePassword2}
          value={password2}
        />
        <button disabled={isLoading} onClick={onClickSignUp}>Sign Up</button>
        <aside id="footer">
          <div>&copy;&nbsp;{new Date().getFullYear()}</div>
          <a href="https://www.linkedin.com/company/fasterhorses-xyz/" target="_blank" rel="noreferrer">LinkedIn</a>
          <a href="https://www.npmjs.com/package/@fasterhorses/sdk" target="_blank" rel="noreferrer">npm</a>
        </aside>
      </section>
    </div>
  </div>;
};

export default Auth;
