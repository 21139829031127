import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';

import Auth from './pages/Auth';
import FAQ from './pages/FAQ';
import Home from './pages/Home';

import './App.css';

TimeAgo.addDefaultLocale(en);

const timeAgo = new TimeAgo('en-US');

const ago = date => timeAgo.format(date);

const App = () => {
  const { pathname } = window.location;

  let component = '404';

  switch (pathname) {
    case '/':
      component = <Home ago={ago} />;

      break;

    case '/login':
    case '/login/':
    case '/signup':
    case '/signup/':
      component = <Auth />;

      break;

    case '/faq':
    case '/faq/':
      component = <FAQ />;

      break;

    default:
      break;
  }

  return component;
};

export default App;
